























































import { Vue, Component } from 'vue-property-decorator';
import { IRole, IUser, ISettings, IResponse } from '@/modules/settings/views/knowledge/model';
import Divider from '@/modules/settings/components/divider/Divider.vue';
import ToggleSwitch from '@/components/ui/toggle-switch/ToggleSwitch.vue';
import ContextPicker from '@/modules/settings/components/context-picker/ContextPicker.vue';
import RolesModule from '@/store/modules/roles/RolesModule';
import TeamsModule from '@/store/modules/teams/TeamsModule';
import ApiClient from '@/api/api.client';
import Notify from '@/services/helpers/notify';
import GeneralModule from '@/store/modules/general/GeneralModule';
import UsersModule from '@/store/modules/users/UsersModule';
import SettingsModule from '@/store/modules/settings/SettingsModule';

@Component({
    components: { Divider, ToggleSwitch, ContextPicker },
})
export default class Knowledge extends Vue {
    public pageLoaded = false;
    public users: Array<IUser> = [];
    public isLoading = false;
    public firstSearchUsers = false;
    public currentUsersPage = 2;
    public currentRolesPage = 2;
    public firstSearchRoles = false;
    public settings = {} as ISettings;
    public access_all = this.settings.enabled ? this.settings.access_all : !this.settings.access_all;
    public updateTimeout: number = null;
    public statuese = false;
    setRoles(roles: Array<IRole>) {
        this.settings.section_editors.roles = roles as never;
        this.updateSettings();
    }

    setUsers(users: Array<IUser>) {
        console.log(users);
        
        this.settings.section_editors.users = users;
        this.updateSettings();
    }

    updateSettings() {
        clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(async () => {
            ApiClient.knowledge.setProjectSettings(this.settings).then(() => {
                Notify.success('Изменения сохранены');
                SettingsModule.fetchSettings();
            });
            await GeneralModule.me().finally(() => 
            {
                TeamsModule.setStatus(UsersModule.currentUser.permitions.knowledge.manage)
            })
        }, 500);
    }
    async loadUsers(payload?: {page: number, search: string}) {
        this.isLoading = true;
        await TeamsModule.fetchTeams(payload).finally(() => 
        {
            this.isLoading = false;
        });
    }
    async loadRoles(payload?: {page: number, search: string})
        {
            this.isLoading = true;
            await RolesModule.fetchRoles(payload).finally(() => 
            {
                this.isLoading = false;
            })
        }
    changeSection(value: boolean) {
        if (value === false) {
            this.settings.access_all = false;
        }
        this.settings.enabled = value;
        this.updateSettings();
    }

    changeAccessAll(value: boolean) {
        this.settings.access_all = value;
        this.updateSettings();
    }
    searchUsers(value: {page: number, search: string})
    {
        this.firstSearchUsers = true;
        this.currentUsersPage = 1;
        TeamsModule.clearTeams();
        this.loadUsers(value)
    }
    searchRoles(value: {page: number, search: string})
    {
        this.firstSearchRoles = true;
        this.currentRolesPage = 1;
        // RolesModule.clearRoles();
        this.loadRoles(value);
    }
    paginateUsers(value: {page: number, search: string})
    {
        if(this.firstSearchUsers)
        {
            this.currentUsersPage++;
            value.page = this.currentUsersPage
        }
        this.loadUsers(value);
        this.firstSearchUsers = false
        this.currentUsersPage++;
    }
    paginateRoles(value: {page: number, search: string})
    {
        if(this.firstSearchRoles)
        {
            this.currentRolesPage++;
            value.page = this.currentRolesPage
        }
        this.loadRoles(value)
        this.firstSearchRoles = false;
        this.currentRolesPage++;
    }
    beforeDestroy()
    {
        // RolesModule.clearRoles();
        TeamsModule.clearTeams();
    }
    async loadSettings() {
        if(!SettingsModule.getSettings.length)
        {
            await SettingsModule.fetchGeneralSettings();
        }
        this.settings = this.sectionSettings.filter((el) => el.slug === 'knowledge')[0].settings;
    }

    async mounted() {
        if(!this.getUsers.length)
        {
            this.loadUsers();
        }
        await this.loadSettings();
        if(this.sectionSettings.length)
        {
            this.pageLoaded = true
        }
    }
    get sectionSettings()
    {
        return SettingsModule.getGeneralSettings
    }
    get permission()
    {
        return TeamsModule.permissionStatus
    }
    get getUsers()
    {
        return TeamsModule.teams.map(el => {
            return { id: el.id, title: `${el.first_name} ${el.last_name}`, img: el.avatar ? el.avatar : null, specialisations: el.specialisations };
        });
    }
    get rolesOptions() {
        return RolesModule.roles.map(el => {
            return { id: el.id, title: el.name };
        });
    }
}
